/* General styles */
body,
html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  /* border-top-left-radius: 40px;
  border-top-right-radius: 40px; */
}

.header {
  text-align: center;
  padding: 20px 0;
  /* background: url("../assets/images/background.jpg") no-repeat center center; */
  background-size: cover;
  /* border-top-left-radius: 40px;
  border-top-right-radius: 40px; */
  border-radius: 0; /* border-radius 속성 제거 */
}

.header h1 {
  font-size: 2.5em;
  color: #00a8e8;
}

.header p {
  font-size: 1.5em;
  color: #0073a9;
}

.bodytop-text {
  margin-top: 2%;
}

.header-text {
  /* letter-spacing: -2px; */
}

.header-text span {
  text-align: left;
  font-size: 1.3em;
  font-weight: bold;
  color: #017b99;
  letter-spacing: -3px;
}

.header-text .underline {
  border-bottom: 3px solid #006699; /* 밑줄 색상과 두께 설정 */
  padding-bottom: 5px; /* 글자와 밑줄 사이의 간격 설정 */
}

.sub-text {
  font-size: 1em;
  font-weight: normal;
  color: #333333;
  text-align: left;
  margin: 0px 2%;
}

.mid-info-home {
  margin-left: 5% !important;
  max-width: 35%;
  height: 80px !important; /* 비율을 유지하면서 높이를 자동으로 조절 */
}
.mid-info-home {
  margin-left: 5% !important;
  max-width: 35%;
  height: 80px !important; /* 비율을 유지하면서 높이를 자동으로 조절 */
}
/* .responsive-image {
  max-width: 100%;
  height: auto;
} */

.image-section {
  width: 100%;
  text-align: center; /* 이미지가 가운데 정렬되도록 설정 */
}

.image-section .responsive-image {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto; /* 이미지를 가운데 정렬 */
  box-shadow: 0 4px 4px -4pxrgba (0, 0, 0, 0.1);
  border-radius: 15px;
}

.image-section .responsive-image-rule {
  width: 60%;
  max-width: 60%;
  height: auto;
  display: block;
  margin: 0 auto; /* 이미지를 가운데 정렬 */
  box-shadow: 0 4px 4px -4pxrgba (0, 0, 0, 0.1);
  border-radius: 15px;
}

.image-section .festivalImage {
  background: url("../assets/images/home/bodyTopImage.png");
  background-size: cover;
  height: auto;
}

.image-section .festivalText {
  padding-right: 50%;
}

.posterSection {
  width: 600px;
}
.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.border-line {
  border-bottom: 2px solid #017b99;
}

/* .border-line-h2 {
  border-bottom: 2px solid #017b99;
  margin-top: -20px;
  margin-bottom: 20px;
  width: 500px;
} */

.event-info {
  max-width: 100%;
  margin: 0px;
  border: 1px solid #ffffff;
  /* padding: 20px; */
  border-radius: 8px;
  /* background-color: #ffffff;
   */
  /* background-color: rgb(252, 252, 245); */
}

/* 지도 */
.event-info h1 {
  text-align: left;
  color: #017b99;
  margin-left: 10px;
  font-size: 50px;
  letter-spacing: -3px;
}

.event-info h2 {
  text-align: center;
  color: #017b99;
  /* margin-left: 390px; */
  font-size: 35px;
  letter-spacing: -3px;
  border-bottom: #017b99;
  margin-top: 0px;
}

.event-info .border-line-h2 {
  /* margin-left: 390px; */
  /* border-bottom: 2px solid #017b99;
  margin-top: -12px;
  margin-bottom: 0px;
  width: 350px; */
}

.event-info .placeaddress {
  padding: 10px;
  text-align: left;
  margin-top: 30px;
}

.event-info .placeaddress div {
  padding: 10px;
  text-align: left;
  font-size: 20px;
  font-weight: 100;
}

.info-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0px;
}

.info-table img {
}

.info-table-shadow img {
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.1);
}

.info-table th,
.info-table td {
  /* border: 1px solid #ccc; */
  padding: 5px;
  /* border-bottom: 2px solid #d1d1d1; */
  font-size: 20px;
  text-align: center;
}

.info-table tr {
  /* margin-bottom: 100px;/ */
}

.info-table th {
  /* border: 1px solid #ccc; */
  padding: 5px;
  text-align: center;
  background-color: rgb(255, 255, 255);
  width: 30%;
}

.info-table .h2title {
  /* border-bottom: 2px solid #d1d1d1; */
}

.info-table .table-bold,
.info-table .table-normal {
  border-bottom: 2px solid #d1d1d1;
}

.info-table .table-bold,
.info-table .bottomTr {
  /* border: 1px solid #ccc; */
  /* padding: 20px; */
  /* padding-left: 50px; */
  text-align: center;
  font-size: large;
  font-weight: bold;
  /* width: 20%; */
}

.info-table .table-normal {
  text-align: left;
  /* border: 1px solid #ccc; */
  /* padding-left: 80px; */
}

.info-table .table-bold-bottom {
  /* border: 1px solid #ccc; */
  /* padding: 20px; */
  /* padding-left: 50px; */
  text-align: center;
  font-size: large;
  font-weight: bold;
  width: 20%;
  /* border-bottom: 2px solid #d1d1d1; */
}

.info-table .table-normal-bottom {
  text-align: left;
  /* border: 1px solid #ccc; */
  /* padding-left: 80px; */
  /* border-bottom: 2px solid #d1d1d1; */
}

.info-table .bottomTr {
  border-bottom: 2px solid #d1d1d1;
}

.event-info .border-line {
  border-bottom: 2px solid #017b99;
}

.rule-div {
  margin-top: 100px;
  margin-bottom: 100px;
}

.notice-text {
  text-align: left;
  margin-top: 100px;
  padding: 10px;
  border-top: 2px solid #017b99;
  border-bottom: 2px solid #017b99;
}

.notice-text .notice-th {
  width: 150px;
  color: #000000;
  margin-bottom: 10px;
  padding: 30px;
}

.notice-text tr,
.notice-text td {
  list-style: none;
  padding: 10px;
  font-size: large;
}

.placeinfo-div {
  margin-top: 150px;
}

.map-div {
  width: 100%;
  height: 500px;

  /* margin-left: 5%; */
}

.modal-content {
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  background-image: url("../assets/images/home/download_back.png");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  /* background-color: lightsteelblue; */
  border-radius: 20px;
  padding: 25px;
  /* width: 600px;
  height: 300px; */
  width: 700px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: none;
  outline: none; /* 포커스 시 테두리 제거 */
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.5); /* 백그라운드 오버레이 */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: none;
}

.download-logo {
  width: 95%;
  margin-bottom: 20px;
}

.modalImage {
  width: 100%;
  height: auto;
  border-radius: 0px;
  /* margin-top: 20px; */
}

/* Modal Content 스타일 */
.modal-content2 {
  border: none; /* 테두리 제거 */
  outline: none; /* 외곽선 제거 */
  position: relative;
  background-color: white;
  border-radius: 0px;
  /* padding: 20px; */
  /* width: 100%; */
  max-width: 600px;
  /* box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); */
  animation: fadeIn 0.3s ease-in-out;
}

.button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.popupCloseBtn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  width: 26px;
  height: 26px;
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
}

.store-btn {
  width: 50%;
  cursor: pointer;
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
}
.popupCloseBtn:hover,
.store-btn:hover {
  transform: scale(1.05); /* 살짝 확대 */
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); */
}
.caniMoreInfoModal {
  margin-top: 20px;
  padding-top: 20px;
  font-size: 30px;
  cursor: pointer;
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
}

.caniMoreInfoModal:hover {
  transform: scale(1.05);
}

/* Responsive styles */
@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }

  .mid-info-home {
    margin-left: 5% !important;
    max-width: 40%;
    height: 40px !important; /* 비율을 유지하면서 높이를 자동으로 조절 */
  }

  .header h1 {
    font-size: 2em;
  }

  .header p {
    font-size: 1.2em;
  }

  .right-section .register-button {
    width: 100%;
  }

  .image-section img {
    max-width: 100%;
    height: auto;
  }
  .responsive-image {
    max-width: 100%;
    height: auto;
  }

  .responsive-image:hover {
  }

  .image-section .responsive-image-rule {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto; /* 이미지를 가운데 정렬 */
    box-shadow: 0 4px 4px -4pxrgba (0, 0, 0, 0.1);
    border-radius: 15px;
  }

  .bodytop-text .header-text span {
    font-size: 1em;
    /* font-size: 3em; */
    font-weight: bold;
    color: #017b99;
    text-align: left;
    letter-spacing: -1.5px;
  }

  .bodytop-text .header-text .underline {
    border-bottom: 2px solid #006699; /* 밑줄 색상과 두께 설정 */
    padding-bottom: 3px; /* 글자와 밑줄 사이의 간격 설정 */
  }
  .event-info {
    max-width: 100%;
    /* margin: 20px auto; */
    border: 1px solid #ffffff;
    padding: 0px;
    border-radius: 8px;
    background-color: #ffffff;
  }

  .event-info .placeaddress {
    padding: 10px;
    text-align: left;
    margin-top: 15px;
  }

  .event-info .placeaddress div {
    padding: 10px;
    text-align: left;
    font-size: 12px;
    font-weight: 100;
  }

  .event-info h1 {
    font-size: 20px;
    letter-spacing: -1.5px;
    /* margin-left: 10; */
  }

  .event-info h2 {
    /* padding: 5px; */
    font-size: 16px;
    letter-spacing: -2px;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .event-info .border-line-h2 {
    /* padding: 3px;
    border-bottom: 1px solid #017b99;
    margin-bottom: 0px;
    width: 150px; */
  }

  .event-info .border-line {
    border-bottom: 1px solid #017b99;
  }

  .info-table th,
  .info-table td {
    padding: 0px;
    font-size: 13px;
    letter-spacing: -0.5px;
    text-align: center;
    border-bottom: none;
  }
  .info-table th {
    /* border: 1px solid #ccc; */
    /* padding: 5px; */
    text-align: center;
    background-color: rgb(255, 255, 255);
    width: 30%;
  }

  .info-table .table-bold {
    /* border: 1px solid #ccc; */
    /* padding: 4px; */
    /* padding-left: 10px; */
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    width: 50px;
    border-bottom: 1px solid #d1d1d1;
    margin-bottom: 0px;
  }

  .info-table .table-normal {
    /* border: 1px solid #ccc; */
    font-size: 11px;
    /* padding-left: 20px; */
    border-bottom: 1px solid #d1d1d1;
  }

  .info-table .table-bold-bottom {
    /* border: 1px solid #ccc; */
    /* padding: 4px; */
    /* padding-left: 10px; */
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    width: 20%;
    border-bottom: 0px;
  }

  .info-table .table-normal-bottom {
    /* border: 1px solid #ccc; */
    font-size: 11px;
    /* padding-left: 20px; */
    border-bottom: 0px;
  }

  .info-table .bottomTr {
    border-bottom: 1px solid #d1d1d1;
  }

  .rule-div {
    margin-bottom: 50px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .notice-text {
    text-align: left;
    margin-top: 40px;
    padding: 5px;
    border-top: 1px solid #017b99;
    border-bottom: 2px solid #017b99;
  }

  .notice-text th {
    margin-bottom: 10px;
    padding: 3px;
  }
  .notice-text tr {
    font-size: 13px;
  }

  .notice-text .notice-th {
    width: 70px;
  }
  .notice-text tr,
  .notice-text td {
    list-style: none;
    /* padding: 10px; */
  }

  .notice-text td {
    font-size: 10px;
  }

  .placeinfo-div {
    margin-top: 50px;
  }

  .map-div {
    width: 100%;
    height: 300px;
  }
  /* 팝업 */
  .modal-content {
    width: 90%; /* 모달 창의 너비를 화면의 90%로 조정 */
    height: auto; /* 높이를 자동으로 조정 */
    padding: 15px;
  }

  .download-logo {
    width: 90%;
    margin-bottom: 10px;
  }

  .store-btn {
    width: 45%; /* 버튼 크기를 조정 */
    margin: 0 5px;
  }
  .popupCloseBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  .tableDateSpan {
    display: block;
  }

  .caniMoreInfoModal {
    font-size: 15px;
    cursor: pointer;
  }
}

@media (max-width: 390px) {
  .info-table .h2title {
    /* border-bottom: 2px solid #d1d1d1; */
    font-size: 13px;
    letter-spacing: -2px;
  }
  /* .event-info h2 {
    font-size: 14px;
    letter-spacing: -2px;
    margin-top: 0px;
  }

  .info-table .table-bold {
    text-align: center;
    font-size: 11px;
  }

  .info-table .table-normal {
    font-size: 10px;
  } */

  .caniMoreInfoModal {
    font-size: 15px;
    cursor: pointer;
  }
}
