@font-face {
  font-family: "OneMobile";
  src: url(./fonts/ONE\ Mobile\ OTF\ Regular.otf);
}

body {
  font-family: "OneMobile";
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}

.container {
  width: 80%;
  margin: 0 auto;
}

.main {
  margin-top: 100px;
}

@media (max-width: 768px) {
  .main {
    margin-top: 10px;
  }
}

@media (max-width: 390px) {
  .main {
    margin-top: 10px;
  }
}
