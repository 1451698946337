/* General styles */
body,
html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.header {
  background: url("../assets/images/banner_ver2.png") no-repeat center center;
  color: rgb(145, 91, 91);
  padding: 20px 0;
  min-height: 400px;
  height: 100%;
  width: 100%;
  border-radius: 0;
  background-size: cover;
  background-position: center;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 0 0px;
  margin: 0 auto;
  position: relative;
  border-radius: 0;
}

.pairInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.pairInfo img {
  height: auto;
  width: auto;
}

.menu-large-text {
  margin-top: -20px;
}
.menu-large-text .text-container .large-title .line {
  margin-left: 100px;
  font-size: 2em; /* 글꼴 크기를 적절하게 조정 */
  font-weight: bold; /* 글꼴 굵기 설정 */
  /* color: #006699;  */
  text-align: center; /* 텍스트 가운데 정렬 */
  margin: 0; /* 기본 여백 제거 */
  margin-bottom: 0px;
  /* border-bottom: 6px solid #ffffff; */
  color: #3366ff;
  text-shadow:
    0 0 12px #ffffff,
    0 0 12px #ffffff,
    0 0 12px #ffffff,
    0 0 12px #ffffff,
    0 0 12px #ffffff,
    0 0 12px #ffffff;
}

.menu-large-text .text-container .large-title .noline {
  font-size: 2em; /* 글꼴 크기를 적절하게 조정 */
  font-weight: bold; /* 글꼴 굵기 설정 */
  /* color: #006699;  */
  text-align: center; /* 텍스트 가운데 정렬 */
  margin: 0; /* 기본 여백 제거 */
  margin-bottom: 0px;
  color: #3366ff;
  text-shadow:
    0 0 12px #ffffff,
    0 0 12px #ffffff,
    0 0 12px #ffffff,
    0 0 12px #ffffff,
    0 0 12px #ffffff,
    0 0 12px #ffffff;
}

.menu-large-text .subtitle {
  /* font-size: 1.2em; */
  font-size: 20px;
  font-weight: 500; /* 글꼴 굵기 설정 */
  color: #000000; /* 글꼴 색상 설정 */
  text-align: center; /* 텍스트 가운데 정렬 */
  margin: 0; /* 기본 여백 제거 */
  padding-top: 25px;
}

/* 배경 색상 및 패딩 추가 */
.text-container {
  /* background-color: #e0f7fa;  */
  padding: 20px; /* 패딩 설정 */
  border-radius: 10px; /* 둥근 모서리 설정 */
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 그림자 추가 */
}

.logo img {
  width: 350px;
  margin-left: 25px;
}

.header-right {
  display: flex;
  align-items: center;
  margin-right: 50px;
}

nav {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.nav-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.nav-links li {
  margin-left: 20px;
  position: relative;
  display: flex;
  align-items: center; /* Center-align the items in the list */
  white-space: nowrap; /* Prevent line break */
}
.nav-links li div,
.nav-links li a {
  text-decoration: none;
  /* color: white; */
  color: #000000;
  font-weight: bold;
  font-size: 18px;
  padding: 10px;
  display: flex;
  align-items: center; /* Center-align the items in the link */
}

.nav-links li span img {
  height: 15px;
}

.nav-links li.active a,
.nav-links li.active div,
.nav-links li a:hover {
  /* color: #ff4081; */
  color: #ff0066;
}

.nav-links .submenu {
  display: none;
  position: absolute;
  top: 100%; /* 부모 요소 바로 아래에 위치 */
  left: 0;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 10px 0;
  margin: 0;
  border-radius: 4px;
  z-index: 1000;
  size: 10px;
}

.nav-links li:hover .submenu {
  display: block;
  width: 150px;
}

.submenu li {
  /* padding: 8px 20px; */
  /* width: auto; */
  text-align: left;
}
.nav-links li .submenu li div,
.nav-links li .submenu li a {
  font-size: small;
  color: #646464;
  text-decoration: none;
  text-align: left;
}
.nav-links li .submenu li a:hover {
  /* background-color: #f1f1f1; */
  color: #ff0066;
}

.sns-icons {
  display: flex;
  align-items: center;
}

.sns-icons a {
  margin-left: 10px;
}

.sns-icons img {
  width: 24px;
  height: 24px;
}

.menu-toggle {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.overlay.active {
  display: block;
}

/* Additional styles for main content */
.main {
  text-align: center;
  padding: 50px 20px;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

p {
  font-size: 1.2rem;
  color: #666;
}

/* Main content styles */
.main {
  text-align: center;
  padding: 0;
}

.mid-Image {
  width: 100%;
  display: block;
}

.event-image {
  width: 100%;
  display: block;
}
.dropdown-icon {
  display: none;
  margin-left: 10px; /* Add space between text and dropdown icon */
}

.clickable {
  cursor: pointer;
}
/* Responsive styles */
@media (max-width: 768px) {
  .header {
    background: url("../assets/images/mobile_banner_일산.png") no-repeat center
      center;
    background-size: cover;
    background-position: center;
    /* min-height: 10vh; */
    height: auto;
    min-height: auto;
    max-width: 100%;
    border-radius: 0;
  }

  .menu-toggle {
    display: block;
    position: absolute;
    color: #ff4081;
    top: 1px;
    right: 20px;
  }

  .header-right {
    display: none;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
    position: absolute;
    top: 40px;
    right: 35px;
    background-color: rgb(255, 255, 255);
    padding: 10px;
    z-index: 3;
    border-radius: 10px;
    margin-right: 0px;
  }

  .header-right.open {
    display: flex;
    width: 120px;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    padding: 0 0px;
    margin: 0 auto;
    position: relative;
    border-radius: 0;
  }

  .pairInfo {
    display: flex;
    justify-content: center;
    /* margin-bottom: 30px; */
    margin-top: -10px;
  }
  .menu-large-text {
    display: flex;
    justify-content: flex-start; /* Flexbox 컨테이너를 왼쪽 정렬 */
    align-items: flex-start; /* Flexbox 아이템을 위쪽 정렬 */
    width: 100%; /* 부모 요소의 너비를 100%로 설정 */
  }

  .text-container {
    text-align: left; /* 텍스트를 왼쪽 정렬 */
    width: 100%; /* 부모 요소의 너비를 100%로 설정 */
  }

  .menu-large-text .text-container .large-title .line {
    font-size: 32px;
    font-weight: bold;
    text-align: left;
    margin: 0;
    margin-bottom: 10px;
    letter-spacing: -2px;
    padding-bottom: 2px;
    /* border-bottom: 3px solid #ffffff; */
    color: #3366ff;
    text-shadow:
      0 0 6px #ffffff,
      0 0 6px #ffffff,
      0 0 6px #ffffff,
      0 0 6px #ffffff,
      0 0 6px #ffffff,
      0 0 6px #ffffff;
  }

  .menu-large-text .text-container .large-title .noline {
    /* font-family: Arial, Helvetica, sans-serif; */
    font-size: 32px;
    font-weight: bold;
    text-align: left;
    margin: 0;
    margin-bottom: 10px;
    letter-spacing: -2px;
    color: #3366ff;
    text-shadow:
      0 0 6px #ffffff,
      0 0 6px #ffffff,
      0 0 6px #ffffff,
      0 0 6px #ffffff,
      0 0 6px #ffffff,
      0 0 6px #ffffff;
  }

  .menu-large-text .subtitle {
    font-size: 10px;
    font-weight: normal;
    text-align: center;
    margin: 0;
    padding-top: 0px;
    /* letter-spacing: -1px;/ */
    /* margin-bottom: 25px; */
  }

  .logo img {
    width: 230px;
    margin-left: 10px;
  }

  nav {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  .nav-links {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .nav-links .submenu {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 10px 0;
    margin: 0;
    border-radius: 4px;
    z-index: 1000;
    margin-top: 25px;
  }

  .nav-links li {
    /* margin: 5px 0; */
    text-align: left;
    width: 100%;
  }

  .nav-links li span {
    /* margin: 5px 0; */
    text-align: right;
    width: 100%;
    /* color: antiquewhite; */
  }
  .nav-links li span img {
    /* margin-right: -10px; */
    height: 9px;
  }

  .nav-links li a,
  .nav-links li div {
    color: #9e9e9e;
    font-weight: bold;
    font-size: 14px;
    padding: 10px;
  }

  /* .nav-links li.active a {
    color: #ff0066;
  } */

  .nav-links li:hover .submenu {
    margin-top: 40px;
    display: block;
    background-color: #dddddd;
    border-radius: 10px;
  }
  .submenu li {
    /* padding: 8px 20px; */
    padding: 0px;
    margin-left: 0px;
  }

  .submenu li a {
    color: #333;
    text-decoration: none;
  }

  .submenu li a:active,
  .submenu li a:hover {
    background-color: #f1f1f1;
  }

  .sns-icons {
    margin-top: 10px;
    display: none;
  }

  .event-image {
    width: 100%;
    display: block;
    height: 100%;
  }

  .main {
    min-height: 400px;
  }

  .dropdown-icon {
    display: inline-block;
  }

  .menu-large-text .text-container {
    /* margin-left: 40px; */
    text-align: center;
  }
}

@media (max-width: 390px) {
  .bodytop-text {
    margin-top: 5%;
  }

  .header-text {
    font-size: 1.2em;
    font-weight: bold;
    color: #017c97;
    text-align: center;
    margin: 0px 5%;
    line-height: 1.3; /* 줄 간격 조정 */
    word-break: keep-all;
    white-space: normal;
  }

  .header-text .underline {
    border-bottom: 2px solid #006699;
    padding-bottom: 5px;
  }

  .sub-text {
    font-size: 0.8em;
    font-weight: bold;
    color: #333333;
    text-align: center;
    margin: 0px 5%;
    line-height: 1.4;
    word-break: keep-all;
    white-space: normal;
  }

  .content {
    flex-direction: column;
  }

  .left-section {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .right-section {
    margin-top: 20px;
  }

  .header h1 {
    font-size: 1.5em;
    line-height: 1.2;
  }

  .header p {
    font-size: 1em;
    line-height: 1.4;
  }

  .right-section .register-button {
    width: 100%;
  }

  .image-section img {
    max-width: 100%;
    height: auto;
  }
  .image-section .responsive-image {
    max-width: 100%;
    height: auto;
  }

  .image-section .responsive-image-QR {
    max-width: 70%;
    height: auto;
  }

  .image-section .responsive-image-button {
    max-width: 70%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  .image-section .company-apply-button {
    width: 250px;
  }

  .inputLabel {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  .selectLabel {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  .informationTextForm {
    margin-left: 0;
    list-style-type: disc;
    padding-left: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.5;
  }

  .informationTextForm ul {
    margin-left: 0;
  }

  .informationTextForm ul li {
    font-size: 12px;
    line-height: 1.4;
  }

  .informationTextForm p {
    font-size: 14px;
    line-height: 1.4;
  }

  .formTextArea {
    height: 180px;
    width: 90%;
    margin: 0 auto;
    line-height: 1.4;
    word-break: keep-all;
    white-space: normal;
  }

  .checkboxLabelAgree {
    font-size: 12px;
  }

  .agreeCheckbox {
    transform: scale(0.8);
  }

  .reserveButton {
    max-width: 200px;
    width: 80%;
    margin: 20px auto;
    display: block;
  }
}
