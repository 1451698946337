.fullscreen-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); /* 배경색과 투명도 조절 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen-modal-content {
  position: relative;
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px;
  background-color: transparent; /* 배경색 제거 */
  border: none;
  cursor: pointer;
  font-size: 0; /* 텍스트 숨기기 */
  background-image: url("../assets/images/home/popup_close.png"); /* 이미지 경로 */
  background-size: contain; /* 이미지가 버튼에 맞게 조정 */
  background-repeat: no-repeat; /* 이미지 반복 방지 */
  width: 40px; /* 이미지 너비 */
  height: 40px; /* 이미지 높이 */
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
}

.modal-poster-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.close-button:hover {
  transform: scale(1.05); /* 살짝 확대 */
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); */
}

.responsive-image-container {
  position: relative;
  display: inline-block;
}

.responsive-image {
  display: block;
  width: 100%;
  height: auto;
}

.hover-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.responsive-image-container:hover .hover-text {
  opacity: 1;
}

/* 모바일 반응형 스타일 */
@media (max-width: 768px) {
  .fullscreen-modal-content {
    width: 95%;
    height: auto;
    padding: 20px;
    outline: none; /* 포커스 시 테두리 제거 */
  }

  .modal-poster-image {
    max-width: 100%;
    max-height: 90%;
  }

  .close-button {
    top: 10px;
    right: 10px;
    padding: 8px 16px;
    font-size: 14px;
    width: 30px; /* 이미지 너비 */
    height: 30px; /* 이미지 높이 */
  }
  .hover-text {
    font-size: 14px; /* 작은 화면에서는 텍스트 크기를 줄입니다. */
    padding: 8px 16px; /* 패딩도 줄입니다. */
  }
}

@media (max-width: 390px) {
  .fullscreen-modal-content {
    width: 100%;
    height: auto;
    padding: 10px;
    outline: none; /* 포커스 시 테두리 제거 */
  }

  .modal-poster-image {
    max-width: 100%;
    max-height: 80%;
  }

  .close-button {
    top: 8px;
    right: 8px;
    padding: 6px 12px;
    font-size: 12px;
    width: 30px; /* 이미지 너비 */
    height: 30px; /* 이미지 높이 */
  }
  .hover-text {
    font-size: 12px; /* 더 작은 화면에서는 텍스트 크기를 더 줄입니다. */
    padding: 6px 12px; /* 패딩도 더 줄입니다. */
  }
}
