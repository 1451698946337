/* General styles */
body,
html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.bodytop-text {
  text-align: center;
  margin-top: 20px;
}

.sub-text {
  font-size: 20px;
  color: #555;
}

.header-text {
  font-size: 36px;
  font-weight: bold;
  color: #017c97;
}

.program-table {
  background-color: rgb(255, 255, 255);
  width: 100%;
  border-collapse: collapse;
  /* border-radius: 10px; */
}

.program-table .table-cell {
  width: 35%;
  padding: 0;
  vertical-align: top;
  /* position: relative; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.program-table .table-cell-image {
  width: 100%;
  padding: 0;
  vertical-align: top;
  box-shadow: none; /* 그림자 제거 */
}

.program-table .table-text {
  width: 50%;
  padding: 5px;
  vertical-align: top;
  text-align: left;
  padding-top: 50px;
  position: relative;

  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.image-section .company-apply-button {
  width: 50%;
  cursor: pointer;
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
}

.program-table .table-text .fixed-title {
  /* position: absolute;
  top: 30;
  left: 0;
  margin: 0; */
  margin-top: 5px;
  margin-bottom: 30px;
  margin-left: 40px;
}
/* .program-table .with-shadow-bottom {
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 15px 0px;
} */

/* .program-table .with-shadow-img img {
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.2); 
  border-radius: 0 0 0px 15px;
} */

.program-table ul {
  list-style-type: none;
  padding-left: 0;
  margin-top: 50px;
  margin-top: 50px;
  margin-left: 50px;
}

.program-table .table-text ul li h3 {
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 30px;
}

.program-table .table-text h2 {
  font-size: 42px;
  color: #3366ff;
  margin-bottom: 10px;
  font-weight: 900;
}

.program-table .table-text ul li h3 {
  font-size: 24px;
  color: #000000;
  margin-bottom: 15px;
  /* border-top: 2px solid #017c97;; */
  /* display: inline-block; */
}

.program-table ul li p {
  margin: 0;
  color: #000000;
}

.program-table .table-text .underline {
  border-top: 2px solid #3366ff;
  padding-bottom: 3px;
  display: inline-block;
  padding-top: 5px;
}

.responsive-image-top {
  width: 100%;
  height: auto;
  display: block;
}
/* 
.responsive-image {
  width: 90%;
  height: auto;
  display: block;
} */

.table-text .programCaniButton {
  margin-top: 30px;
  width: 32em;
  cursor: pointer;
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
}

.programCaniButton:hover {
  transform: scale(1.05);
}

.tables-container {
  display: flex;
  justify-content: space-between; /* 테이블 간의 간격 조절 */
  gap: 10px; /* 테이블 간의 여백 추가 */
}

/* 상담존과 체험존 스타일 */
.zone-table {
  background-color: rgb(255, 255, 255);
  width: 50%;
  border-collapse: collapse;
  margin-top: 20px;
  /* border: #555; */
  text-align: center;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2); /* 그림자 추가 */
  border-radius: 10px;
}

.zone-table .table-text {
  padding: 30px;
}

.zone-table th {
  font-size: 30px;
  color: #3366ff;
  padding: 10px 0;
  border-bottom: 2.5px solid #3366ff;
}

.zone-table td {
  font-size: 18px;
  padding: 10px;
  vertical-align: top;
  text-align: left;
  /* border-bottom: 1px solid #f8f9fa; */
}

.zone-table .table-text .underline {
  border-top: 2px solid #3366ff;
  padding-top: 5px;
  display: inline-block;
}

.caniwalingRule {
  width: auto;
  height: auto;
}
@media (max-width: 768px) {
  .bodytop-text .header-text .underline {
    border-bottom: 1px solid #3366ff; /* 밑줄 색상과 두께 설정 */
    padding-bottom: 3px; /* 글자와 밑줄 사이의 간격 설정 */
  }

  .header-text {
    font-size: 20px; /* 줄어든 글자 크기 */
  }

  .sub-text {
    font-size: 14px; /* 줄어든 글자 크기 */
  }

  .responsive-image-top {
    width: 100%;
    height: auto;
    display: block;
  }

  .responsive-image {
    width: 100%;
    height: 320px;
    /* height: auto; */
    display: block;
  }

  .program-table .table-text .fixed-title {
    margin-left: 10px;
  }

  .program-table .table-text {
    width: 50%;
    padding: 5px;
    vertical-align: center;
    text-align: left;
  }

  .program-table ul {
    list-style-type: none;
    padding-left: 10;
    margin-top: 10px;
    margin-left: 10px;
  }

  .program-table ul li {
    padding-left: 5px;
    font-size: 10px; /* 줄어든 글자 크기 */
    margin-bottom: 2px; /* 줄어든 여백 */
  }

  .program-table .table-text .underline {
    border-top: 1px solid #3366ff;
    padding-bottom: 2px;
    display: inline-block;
    padding-top: 3px;
  }
  .program-table .table-text h2 {
    padding-left: 5px;
    font-size: 18px;
    color: #3366ff;
    margin-top: -2px;
    margin-bottom: -10px;
  }

  .program-table .table-text ul li h3 {
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .program-table ul li p {
    font-size: 9px;
    margin-bottom: 0px;
    display: inline-block;
  }

  .zone-table .table-text {
    padding: 10px;
    /* margin-left: 5px; */
  }
  .zone-table tr {
    /* border-top: 1px solid #017c97;  */
  }

  .zone-table th {
    font-size: 17px;
    font-weight: bold;
    color: #3366ff;
    padding: 5px 0;
    border-bottom: 1px solid #3366ff;
  }

  .zone-table td {
    font-size: 10px; /* 줄어든 글자 크기 */
  }

  .zone-table th {
    padding: 5px 0;
  }

  .zone-table .table-text .underline {
    border-top: 1px solid #3366ff;
    padding-bottom: 0px;
    padding-top: 3px;
    display: inline-block;
  }
  .table-text .programCaniButton {
    width: 17em;
    margin-top: 10px;
  }

  .table-text .mobileSpan {
    display: block;
  }

  .caniwalingRule {
    width: auto;
    height: auto;
    max-width: 350px;
  }
}

@media (max-width: 390px) {
  .bodytop-text .header-text .underline {
    border-bottom: 1px solid #3366ff; /* 밑줄 색상과 두께 설정 */
    padding-bottom: 3px; /* 글자와 밑줄 사이의 간격 설정 */
  }

  .header-text {
    font-size: 20px; /* 줄어든 글자 크기 */
  }

  .sub-text {
    font-size: 14px; /* 줄어든 글자 크기 */
  }

  .responsive-image-top {
    width: 100%;
    height: auto;
    display: block;
  }

  .responsive-image {
    width: 100%;
    height: 320px;
    /* height: auto; */
    display: block;
  }

  .program-table .table-text .fixed-title {
    margin-left: 10px;
  }

  .program-table .table-text {
    width: 50%;
    padding: 5px;
    vertical-align: center;
    text-align: left;
  }

  .program-table ul {
    list-style-type: none;
    padding-left: 10;
    margin-top: 10px;
    margin-left: 10px;
  }

  .program-table ul li {
    padding-left: 5px;
    font-size: 10px; /* 줄어든 글자 크기 */
    margin-bottom: 2px; /* 줄어든 여백 */
  }

  .program-table .table-text .underline {
    border-top: 1px solid #3366ff;
    padding-bottom: 2px;
    display: inline-block;
    padding-top: 3px;
  }
  .program-table .table-text h2 {
    padding-left: 5px;
    font-size: 18px;
    color: #3366ff;
    margin-top: -2px;
    margin-bottom: -10px;
  }

  .program-table .table-text ul li h3 {
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .program-table ul li p {
    font-size: 9px;
    margin-bottom: 0px;
    display: inline-block;
  }

  .zone-table .table-text {
    padding: 10px;
    /* margin-left: 5px; */
  }
  .zone-table tr {
    /* border-top: 1px solid #017c97;  */
  }

  .zone-table th {
    font-size: 17px;
    font-weight: bold;
    color: #3366ff;
    padding: 5px 0;
    border-bottom: 1px solid #3366ff;
  }

  .zone-table td {
    font-size: 10px; /* 줄어든 글자 크기 */
  }

  .zone-table th {
    padding: 5px 0;
  }

  .zone-table .table-text .underline {
    border-top: 1px solid #3366ff;
    padding-bottom: 0px;
    padding-top: 3px;
    display: inline-block;
  }
  .table-text .programCaniButton {
    width: 17em;
    margin-top: 10px;
  }

  .table-text .mobileSpan {
    display: block;
  }

  .caniwalingRule {
    width: auto;
    height: auto;
    max-width: 350px;
  }
}
