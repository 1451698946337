/* General styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.footer {
  background-color: #333;
  color: rgb(223, 216, 216);
  padding: 20px 0;
  font-size: 12px;
}

.footer-container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-info p,
.footer-bottom p {
  color: rgb(223, 216, 216);
  margin: 5px 0;
  font-size: 10px; /* 기본 글자 크기 설정 */
}

.footer-bottom p .copyright {
  color: rgb(223, 216, 216);
  margin: 5px 0;
  font-size: 10px; /* 기본 글자 크기 설정 */
}

.footer-info p {
  font-size: 12px; /* 추가로 글자 크기 조정 */
}

.footer-bottom p {
  font-size: 16px; /* 추가로 글자 크기 조정 */
}

.footer-top {
  display: flex;
  justify-content: flex-end;
}

.footer-top ul {
  list-style: none;
  padding: 0;
  display: flex;
}

.footer-top ul li {
  margin-left: 20px;
}

.footer-top ul li a {
  color: white;
  text-decoration: none;
}

.footer-main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0;
}

.footer-logo img {
  width: 300px;
}

.footer-info {
  flex: 1;
  max-width: 1200px;
  line-height: 1.5;
  margin-left: 20px;
}

.footer-icons {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.footer-icons a {
  margin: 5px 5px;
}

.footer-icons img {
  width: 23px;
  height: 22px;
}

.footer-bottom {
  text-align: center;
  padding: 10px 0;
  border-top: 1px solid #444;
}

.top-button {
  position: fixed;
  bottom: 100px;
  right: 50px;
}

.top-button a {
  background-color: #686868;
  color: white;
  padding: 10px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 13px;
}

.mobileEmail {
  margin-left: 10px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .footer-top {
    justify-content: center;
  }

  .footer-main {
    flex-direction: column;
    align-items: center;
  }

  .footer-info {
    margin-top: 20px;
  }

  .footer-icons img {
    width: 21px;
    height: 20x;
  }
  .top-button {
    position: fixed;
    bottom: 55px;
    right: 20px;
    font-size: 9px;
    z-index: 999;
  }

  .top-button a {
    background-color: #686868;
    color: white;
    padding: 8px;
    text-decoration: none;
    border-radius: 5px;
    bottom: 55px;
    right: 10px;
  }

  .mobileEmail {
    display: block;
    margin-left: 0px;
  }
}

@media (max-width: 390px) {
  .footer-top {
    justify-content: center;
  }

  .footer-main {
    flex-direction: column;
    align-items: center;
  }

  .footer-info {
    margin-top: 20px;
  }

  .footer-icons img {
    width: 21px;
    height: 20x;
  }
  .top-button {
    position: fixed;
    bottom: 55px;
    right: 20px;
    font-size: 9px;
    z-index: 999;
  }

  .top-button a {
    background-color: #686868;
    color: white;
    padding: 8px;
    text-decoration: none;
    border-radius: 5px;
    bottom: 55px;
    right: 10px;
  }

  .mobileEmail {
    display: block;
    margin-left: 0px;
  }
}
