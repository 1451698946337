/* General styles */
body,
html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  /* border-top-left-radius: 40px;
  border-top-right-radius: 40px; */
}

.header {
  text-align: center;
  padding: 20px 0;
  /* background: url("../assets/images/background.jpg") no-repeat center center; */
  background-size: cover;
  /* border-top-left-radius: 40px;
  border-top-right-radius: 40px; */
  border-radius: 0; /* border-radius 속성 제거 */
}

.header h1 {
  font-size: 2.5em;
  color: #00a8e8;
}

.header p {
  font-size: 1.5em;
  color: #0073a9;
}

.bodytop-text {
  margin-top: 2%;
}

.header-text {
  font-size: 1.5em;
  font-weight: bold;
  color: #017c97;
  text-align: left;
  margin: 0px 2%;
  letter-spacing: -2px;
}

.header-text .underline {
  border-bottom: 3px solid #006699; /* 밑줄 색상과 두께 설정 */
  padding-bottom: 5px; /* 글자와 밑줄 사이의 간격 설정 */
}

.sub-text {
  font-size: 1em;
  font-weight: normal;
  color: #333333;
  text-align: left;
  margin: 0px 2%;
}

.mid-info {
  max-width: 100%;
}

.image-section {
  width: 100%;
  margin-bottom: 20px;
  text-align: center; /* 이미지가 가운데 정렬되도록 설정 */
}

.image-section .responsive-image {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto; /* 이미지를 가운데 정렬 */
}

.image-section .responsive-image-reserve {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto; /* 이미지를 가운데 정렬 */
}

.image-section .responsive-image-QR {
  width: 300px;
  max-width: 300px;
  height: auto;
}

.image-section .responsive-image-button {
  width: 300px;
  max-width: 300px;
  height: auto;
  cursor: pointer; /* 손가락 커서 표시 */
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.left-section {
  flex: 1;
  margin-right: 20px;
}

.left-section img {
  width: 100%;
  border-radius: 10px;
}

.right-section {
  flex: 1;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.right-section img {
  width: 100%;
  border-radius: 10px;
}

.right-section h2 {
  color: #0073a9;
  border-bottom: 2px solid #00a8e8;
  padding-bottom: 10px;
}

.right-section ul {
  list-style: none;
  padding: 0;
}

.right-section ul li {
  margin-bottom: 10px;
}

.right-section ul li span {
  display: inline-block;
  min-width: 80px;
  font-weight: bold;
}

.right-section .notice {
  margin-top: 20px;
  font-size: 0.9em;
  color: #333;
}

.right-section .register-button {
  justify-content: center;
  align-items: center;
  display: block;
  width: 93%;
  text-align: center;
  background-color: #000000;
  color: white;
  text-decoration: none;
  border-radius: 15px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.register-button:hover {
  background-color: #a98a00;
}
.image-section .applyButton {
  display: inline-block;
  content: url("../assets/images/reserve/참가신청하기버튼_pc.png");
  margin-top: 5%;
  margin-bottom: 5%;
  width: 50%;
  cursor: pointer;
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
}
.applyButton:hover {
  transform: scale(1.05);
}

.image-section .applyCheckButton {
  display: inline-block;
  content: url("../assets/images/reserve/참가신청확인하기버튼_pc.png");
  margin-top: 5%;
  margin-bottom: 5%;
  width: 50%;
  cursor: pointer;
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
}

.applyCheckButton:hover {
  transform: scale(1.05);
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* 원하는 상단 간격 */
}

.applyButton {
  width: 200px; /* 버튼의 너비 설정 */
  height: auto; /* 높이는 자동으로 설정 */
  cursor: pointer;
}

.textDiv {
  text-align: left;
  margin-bottom: 5px;
  color: #3366ff;
}
.totalFormDiv {
  width: 90%;
}
.inputLabel {
  /* width: 978px; */
  text-align: left;
  font-weight: 600;
  margin-bottom: 30px;

  max-width: 978px;
}

.selectLabel {
  text-align: left;
  font-weight: 600;
  margin-bottom: 30px;
  color: #3366ff;
  margin-bottom: 30px;
}

.fieldsetLabel {
  text-align: left;
  font-weight: 600;
  margin-bottom: 30px;
  color: #3366ff;
  padding-left: 10px;
}

.legendLabel {
  text-align: center;
  font-weight: 600;
  margin-bottom: 30px;
  color: #3366ff;
}

.filedset {
  margin-bottom: 30px;
}

.checkboxLabel {
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
}

.checkboxLabelInput {
  font-size: 10px;
  width: 40px;
}

.checkboxLabelAgree {
  display: flex;
  justify-content: center; /* 수평 중앙 정렬 */
  align-items: center;
  margin-right: 8px;
  margin-top: 30px;
}
.agreeCheckbox {
  width: 20px;
  align-items: center;
  vertical-align: middle;
}

.checkboxLabelAgree input {
  vertical-align: middle;
  /* margin-left: -40px; */
}
.checkboxLabelAgree span {
  font-size: 18px;
  vertical-align: middle;
  /* margin-left: -100px; */
}

.petSizeText {
  font-weight: 100;
  font-size: 15px;
}

.breedDropdown {
  border: 1px solid #ccc;
  max-height: 350px;
  overflow-y: auto;
  background: white;
  position: absolute;
  /* width: 100%; */
  z-index: 1;
}

.breedOption {
  padding: 8px;
  cursor: pointer;
}

.breedOption:hover {
  background-color: #f0f0f0;
}

/* Event form styles */
.form-container {
  /* background-color: #f9f9f9; */
  /* padding: 20px; */
  border-radius: 8px;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
/* 인라인 스타일을 CSS 파일로 옮김 */
.informationTextForm {
  font-size: 16px;
  color: #333;
  line-height: 1.6;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
}

.informationTextForm h2 {
  font-size: 28px;
  font-weight: bold;
  color: #3366ff;
}

.informationTextForm h2 div {
  font-size: 23px;
}

.informationTextForm ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
  margin-left: 150px;
  margin-top: 40px;
}

.informationTextForm li {
  margin-bottom: 8px; /* 각 리스트 항목 간격 조정 */
}

.informationTextForm p:last-of-type {
  font-weight: bold;
  color: #3366ff;
  margin-top: 50px;
}

.inputCotainer {
  background-color: #ffffff;

  max-width: 500px;
  width: 80%;
  margin: 0 auto;
}

.reserveCheckButton {
  width: 200px;
  border: none; /* 테두리선 제거 */
  box-shadow: none; /* 그림자 제거 */
  margin-top: 30px;
  cursor: pointer;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 15px;
  font-size: 16px;
  color: #333;
}

input,
select {
  padding: 10px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
}

input:focus,
select:focus {
  outline: none;
  border-color: #3366ff;
}

small {
  display: block;
  margin-top: 5px;
  color: #666;
}

.submit-btn {
  background-color: #3366ff;
  color: #fff;
  border: none;
  padding: 12px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #3366ff;
}

.reserveButton {
  margin-top: 30px;
  width: 15em;
  cursor: pointer;
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
}

.reserveButton:hover {
  transform: scale(1.05);
}

.checkButton {
  margin-top: 30px;
  width: 12em;
  cursor: pointer;
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
}

.checkButton:hover {
  transform: scale(1.05);
}

.successCheckButton {
  background-color: #3366ff;
  color: #fff;
  border: none;
  padding: 12px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.refreshButton {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.refreshButton:hover {
  background-color: #218838;
}

.formTextArea {
  width: 94%;
  height: 200px;
  margin: 30 30;
  resize: none;
  margin-bottom: 20px;
  padding: 20px;
}

.caniMoreInfo {
  font-size: 1.7em;
  cursor: pointer;
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;

  margin-bottom: 50px;
}

.caniMoreInfo:hover {
  transform: scale(1.05);
}

/* Responsive styles */
@media (max-width: 768px) {
  .bodytop-text {
    margin-top: 2%;
  }

  .header-text {
    font-size: 1em;
    font-weight: bold;
    color: #017c97;
    text-align: left;
    margin: 0px 2%;
    line-height: 1.2; /* 줄바꿈 조정 */
    word-break: keep-all; /* 단어 단위로 줄바꿈 */
    white-space: normal; /* 줄바꿈 허용 */
  }

  .header-text .underline {
    border-bottom: 2px solid #006699; /* 밑줄 색상과 두께 설정 */
    padding-bottom: 5px; /* 글자와 밑줄 사이의 간격 설정 */
  }

  .sub-text {
    font-size: 0.5em;
    font-weight: bold;
    color: #333333;
    text-align: left;
    margin: 0px 2%;
    line-height: 1.4; /* 줄바꿈 조정 */
    word-break: keep-all;
    white-space: normal;
  }

  .content {
    flex-direction: column;
  }

  .left-section {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .right-section {
    margin-top: 20px;
  }

  .header h1 {
    font-size: 2em;
    line-height: 1.2; /* iPhone 화면에서 줄바꿈 최소화 */
  }

  .header p {
    font-size: 1.2em;
    line-height: 1.4;
  }

  .right-section .register-button {
    width: 100%;
  }

  .image-section img {
    max-width: 100%;
    height: auto;
  }
  .image-section .responsive-image {
    max-width: 100%;
    height: auto;
  }

  .image-section .responsive-image-QR {
    max-width: 50%;
    height: auto;
  }

  .image-section .responsive-image-button {
    max-width: 50%;
    height: auto;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  .image-section .applyButton {
    display: inline-block;
    content: url("../assets/images/reserve/참가신청하기버튼_mobile.png");
    margin-top: 5%;
    margin-bottom: 5%;
    width: 50%;
    cursor: pointer;
    transition:
      transform 0.2s ease,
      box-shadow 0.2s ease;
  }
  .applyButtonPc:hover {
    transform: scale(1.05);
  }

  .image-section .applyCheckButton {
    display: inline-block;
    content: url("../assets/images/reserve/참가신청확인하기버튼_mobile.png");
    margin-top: 5%;
    margin-bottom: 5%;
    width: 50%;
    cursor: pointer;
    transition:
      transform 0.2s ease,
      box-shadow 0.2s ease;
  }

  .applyButtonMobile:hover {
    transform: scale(1.05);
  }

  .giftInfoText {
    font-size: 12px;
  }

  .selectLabel {
    /* width: 350px; */
  }
  .inputLabel {
    width: 95%;
  }

  .fieldsetLabel {
    width: 95%;
  }
  .informationTextForm {
    margin-left: 0px;
    list-style-type: disc;
    padding-left: 20px;
    text-align: center;
    font-size: 13px;
    line-height: 1.5; /* iPhone에서 줄바꿈 최소화 */
  }

  .informationTextForm ul {
    margin-left: 0px;
  }

  .informationTextForm ul li {
    font-size: 13px;
    line-height: 1.4;
  }

  .informationTextForm p {
    font-size: 15px;
    line-height: 1.4;
  }

  .formTextArea {
    width: 90%;
    height: 200px;
    line-height: 1.4;
    word-break: keep-all;
    white-space: normal;
  }
}

@media (max-width: 390px) {
}
